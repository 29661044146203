@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Montserrat-Light.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Montserrat-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
       /* url('./assets/fonts/MontserratAlternates-Light.svg#svgFontName') format('svg'); Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-regular';
  src: url('./assets/fonts/Montserrat-Regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Montserrat-Regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/Montserrat-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
       /* url('./assets/fonts/MontserratAlternates-Light.svg#svgFontName') format('svg'); Legacy iOS */
}

html,
body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

    font-family: 'Montserrat-regular';
  margin: 0;
  /*background-color: rgb(245, 245, 245);*/
}



.container {
  margin: 90px auto;
  max-width: 900px;
}

a 
{
  text-decoration: none;
}


.middleSection {
  display: flex;
  height: 80px;
  max-width: 520px;
  margin: 0 auto;
  background: linear-gradient(90deg, #FFD772 0%, #F5B100 100%);
  border-radius: 0px 0px 26px 26px;
}

/*.middleSection div {*/
/*  margin: 24px;*/
/*}*/

.active {
  color: red;
}
/* .makeStyles-search-4 {
  margin-left: 10px !important;
  margin-right: 10px !important;
} */

.navBarTitle {
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  /*text-align: center;*/
  letter-spacing: -0.165px;
  /* grey dark */
  color: #505050;

  margin: 24px;
}

.searchBar {
  margin: 18px;
  width: 63%;
  padding-right: 30px;
}

.searchBarInput {
  background: #FFFFFF;
  border-radius: 21px;
  padding: 10px;
  width: 100%;

  border-color: beige;
  border-left-color: #ffffff42;
  border-top-color: #ffffff42;
}

.navBarTitleContainer{
  width: 45%;
}

.logoContainer {
  margin-top: 15px;
  margin-left: 15px;
}

.userProfileImage {
  display: flex;
  margin: 20px;
}

.userNameSection {
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.165px;

  /* grey dark */

  color: #505050;

  margin: 0 auto;
  padding-top: 5px;
}

.container_space {
  margin-top: 20px;
}

.navigationContainer{
  padding-top: 1px;
  background: #F1F1F1;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  /*border-radius: 26px;*/
}

.aboutPageContainer {
  background: #F1F1F1;
  width: 100%;
  padding: 20px 0px;
}

.mainContainer_feeds {
  position: relative;
  width: 555px;
  max-width: 555px !important;
  margin: 0 auto !important;
}

.right_section_filter 
{
  height: 780px;
  border-radius: 26px;
}

.rightSectionBanner
{
  background: url('assets/images/ADBannerProfile.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  float: left;
  width: 100%;
  /* height: 780px; */
  border-radius: 26px;
}

ul {
  list-style-type: none;
}

.socialize_btn {
  background: linear-gradient(90.06deg, #9A77CF 0.06%, #9A77CF 0.07%, #4A287D 99.95%);
  border-radius: 26px 26px 26px 0px;
  color: white;
  padding: 22px;
  font-size: 30px;
  width: 100%;
  max-width: 100%;
}

.shoutouts_btn {
  background: linear-gradient(90deg, #FFD772 0%, #F5B100 100%);
  border-radius: 0px 26px;
  color: white;
  padding: 16px 22px;
  font-size: 30px;
  width: 100%;
  max-width: 100%;
}

.localhub_btn {
  background: linear-gradient(98.46deg, #FF8372 1.1%, #FF004F 100%);
  border-radius: 26px 0px 0px 26px;
  color: white;
  padding: 16px 22px;
  font-size: 30px;
  width: 100%;
  max-width: 100%;
}

.outfits_btn {
  background: linear-gradient(166.47deg, #7C75D2 0%, #262254 100%);
  border-radius: 26px 0px;
  color: white;
  padding: 22px;
  font-size: 30px;
  width: 100%;
  max-width: 100%;
}

.menuNavigationSection li {
  display: flex;
  margin-top: 25px;
  position: relative;
}

.menuNavigationSection ul {
  padding: 25px;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}

.active_btn {
  position: relative;
  right: -25px !important;
}

.active_dot_def {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 22px;
  top: 35px;
}

.soc_dot_design {
  background: linear-gradient(90.06deg, #9A77CF 0.06%, #9A77CF 0.07%, #4A287D 99.95%);
}
.shou_dot_design {
  background: linear-gradient(90deg, #FFD772 0%, #F5B100 100%);
}
.local_dot_design {
  background: linear-gradient(98.46deg, #FF8372 1.1%, #FF004F 100%);
}
.out_dot_design {
  background: linear-gradient(166.47deg, #7C75D2 0%, #262254 100%);
}

.shoutouts_btn span {
  font-size: 16px;
  display: flex;
  font-weight: bold;
}

.localhub_btn span {
  font-size: 16px;
  display: flex;
  font-weight: bold;
}

.pageDetailsSection h3 {
  width: 40%;
  margin: 0px 25px;
  /*font-family: 'Montserrat';*/
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.165px;
  text-transform: uppercase;

  /* blue gradient */

  background: linear-gradient(166.47deg, #7C75D2 0%, #262254 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.pageDetailsSection p {
  /*font-style: normal;*/
  font-weight: 200;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: -0.165px;

  /* grey normal */
  color: #747474;

  margin: 25px;
  margin-top: 15px;
}

.setStatusSearch .statusBlock{
  background: #F1F1F1;
  width: 100%;
  border-radius: 0px 0px 26px 26px;
  margin-top: 2px;

}
/*Action status block*/
.statusBlock {
  display: flex;
  /*padding-top: 25px;*/
}

/*Active status label*/
.statusBlock .availableLabel
{
  color: #505050;
  font-size: 17px;
  padding: 20px 25px;
  width: 60%;
  font-style: normal;
  font-weight: 700;
}

.statusBlock .availableButton
{
  width: 40%;
}

.availableButton label
{
  float: right;
  margin: 10px;
}

.userBoxStats
{
  width: 160px;
  height: 210px;
  float: left;
  background-position: center;
  background-size: cover;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
}

.userBoxStats label
{
  color: white;
  font-size: 12px;
  font-weight: 700;

  position: absolute;
  width: 80px;
  margin-top: 17px;
}

.userBoxDetailStat
{
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ffffff00 0.06%, #00000017 0.07%, #00000094 99.95%);
  border-radius: 15px;
}

.personDescriptionBox
{
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.personDescriptionBox .personDescriptionBox_des
{
  color: white;
  font-size: 10px;
  font-weight: 700;
  float: left;
  width: 109px;
  margin-left: 11px;
}

.addUserIcon
{
  cursor: pointer;
}

.active_dot_class
{
  position: absolute;
  left: 33px;
  top: 32px;
}

.personDescriptionBox img
{
  float: right;
}

.icon_profile
{
  float: left;
  margin: 10px;
  margin-right: 8px;
}

.medal_class
{
  float: right;
  margin-right: 6px;
  margin-top: 10px;
}

.shotouts_container
{
  margin: 0px 19px;
}

.shotouts_container .postImage
{
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  width: 100%;
}

.postImage ._2qwzr
{
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.postImageMedia
{
  position: relative;
}

.likeDislikeSection
{
  position: absolute;
  bottom: 15px;
  right: 0px;
  display: grid;
  display: inline-grid;
}

.likeDislikeSection .postLikeImage,
.likeDislikeSection .postDislikeImage
{
  cursor: pointer;
}

.newPostImage
{
  position: absolute;
  bottom: 40%;
  right: -35px;
  z-index: 1;
}

.postDislikeImage
{
  margin-top: 10px;
}


/* Shout Outs // part */
.postDetailsSection
{
  width: 100%;
  background: #F1F1F1;
  border-radius: 24px;
  margin-bottom: 20px;
}

.profileRing
{
  height: 38px;
  width: 38px;
  border-radius: 35px;
  background: white;
  position: relative;
}

.postProfileImage
{
  height: 34px;
  width: 34px;
  border-radius: 35px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.postTextSection
{
  padding: 16px;
  width: 100%;
  display: flex;

}

.userImageSection
{
  width: 10%;
}

.postDetailTextSection
{
  width: 82%;
}

.username
{
  font-weight: 700;
  font-size: 14px;

  width: 100%;
  float: left;
}

.username img
{
  width: 14px;
  margin-right: 10px;
}

.socialPostBtns
{
  float: right;
}

.username_text_section
{
  float: left;
  font-size: 14px;
}

.postDetailContainer
{
  float: left;
  width: 100%;
  margin: 8px 0px;

  font-weight: 700;
  font-size: 14px;

  color: rgba(116, 149, 194, 1);
}

.postTextContainer
{
  margin: 10px 0px;
  color: rgba(116, 116, 116, 1);
  font-weight: 400;
  font-size: 12px;

  float: left;
  width: 100%;
}

.waveSurferContainerClass
{
  float: left;
  width: 100%;
  background-color: #FFCB44;
  border-radius: 10px;
}

.waveSurferContainerClass wave
{
  /* height: 47px !important; */
  width: 100%;
}

/* .waveSurferContainerClass canvas
{
  width: 100% !important;
} */

.commentTestContainer
{
  float: left;
  width: 100%;
  margin: 11px 0px;
  position: relative;
}

.commentTestContainer input
{
  width: 95%;
  float: left;
  border-radius: 19px;
  padding: 10px;
  border: white;
  background: white;
}

.mediaControlClass
{
  float: left;
  width: 5%;
  padding: 12px;
  padding-top: 10px;
  padding-bottom: 6px
}

.soundTimeLineClass
{
  width: 74%;
  border-radius: 10px;
  float: left;
}

.userNameContainer
{
  width: 100%;
  float: left;
}

.mediaTimeFrameClass
{
  float: left;
  padding: 14px 7px;
  padding-left: 20px;
  color: #747474;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.sendImageBtn
{
  width: 14px;
  right: 10px;
  height: 14px;
  margin: 10px;
  position: absolute;
}

.tt
{
  width: 25px;
  height: 25px;
  background: aquamarine;
}

.blureBody 
{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}


.modalBoxClass
{
  position: relative;
}

.modalLogoSection
{
  position: absolute;
  float: left;
  width: 100%;
  top: 170px;
}

.modalLogoSection img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modalTitleBox
{
  margin-top: 100px;
}

.modalTitleBox p
{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #747474;
}

.modalBodyDescriptionBox
{
  margin: 30px 94px;
  text-align: center;
}

.modalBodyDescriptionBox p 
{
  font-size: 14px;
  color: #747474;
}

.closeModalImage
{
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.modalBoxClass
{
  padding-bottom: 25px;
}

.modalLinkSectionBox div
{
  margin: 0 auto;
  display: table;
}

.modalLinkSectionBox input
{
  margin: 10px;
}


.video-react .video-react-big-play-button
{
  border-radius: 100px !important;
  width: 47px !important;
  height: 47px !important;
  top: 45% !important;
  left: 45% !important;
}

.postImageMedia .video-react-controls-enabled,
.postImageMedia .video-react-controls-enabled video
{
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.searchInputBlock
{
  position: relative;
}

.searchIconImage
{
  position: absolute;
  top: 13px;
  right: -10px;
}

.userProfile_container
{
  margin: 0px 19px;
}

.singleUserProfileImage
{
  width: 200px;
  height: 200px;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  object-fit: cover;
  object-position: 40% 10%; /* try 20px 10px */ 
}

.userProfileImageSection
{
  position: relative;
  width: 200px;
  height: 200px;
  float: left;
  margin-right: 20px;
}

.singleUserProfileImage
{

}

.editUserImageButton
{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.mainUserProfileDetailsSection
{
  float: left;
}

.mainUserProfileDetailsSection label
{
  font-size: 40px;
  font-weight: 400;
}

.mainUserProfileDetailsSection .userAge
{
  color: #747474;
  font-weight: 700;
  font-size: 14px;
}

.mainUserProfileDetailsSection .statusLanguage
{
  color: #747474;
  font-weight: 700;
  font-size: 14px;
  margin-top: 3px;
} 

.profileDetailsSection
{
  width: 100%;
  float: left;
  display: inline-flex;
  margin-top: 10px;
}

.profileDetailsSection .profilePointsSection,
.profileDetailsSection .profileLocationSection,
.profileDetailsSection .profileReviewSection
{
  margin-right: 50px;
  text-align: center;
  color:  #747474;
}

.profileDetailsSection .profilePointsSection input,
.profileDetailsSection .profileLocationSection input,
.profileDetailsSection .profileReviewSection input
{
  width: 40px;
}

.profileDetailsSection .profilePointsSection div:first-of-type,
.profileDetailsSection .profileLocationSection div:first-of-type,
.profileDetailsSection .profileReviewSection div:first-of-type
{
  font-size: 35px;
}

.profileDetailsSection .profilePointsSection div:nth-of-type(2),
.profileDetailsSection .profileLocationSection div:nth-of-type(2),
.profileDetailsSection .profileReviewSection div:nth-of-type(2)
{
  font-weight: 900;
}

.profileReviewSection
{
  margin-right: 0px !important;
}



.profileDescriptionTextareaSection
{
  width: 100%;
  float: left;
  
}

.profileDescriptionTextareaSection
{
  width: 100%;
  border: 3px solid #F1F1F1;
  padding: 10px;
  margin-top: 20px;
  border-radius: 26px;
  min-height: 90px;
  height: 100%;
  box-sizing: border-box;

  color: #505050;
  font-size: 14px;

}


.profileLinkedAccountSection
{
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;

}

.profileLinkedAccountSection label
{
  color: #747474;
  font-size: 16px;
  font-weight: bold;
}

.loadingImageSection
{
  position: absolute;
  background: white;
  width: 95%;
  min-height: 600px;
  margin: 0 15px;
  box-sizing: border-box;
  top: 0px;
  z-index: 2;
}

.loadingImageSection input
{
  display: flex;
  margin: 40px auto;
  width: 40px;
}

@media (min-height: 650px) 
{
  .loadingImageSection
  {
    height: 650px;
  }
}

@media (min-height: 750px) 
{
  .loadingImageSection
  {
    height: 750px;
  }
}

@media (min-height: 950px) 
{
  .loadingImageSection
  {
    height: 950px;
  }
}

.linkedAccountImageBlock
{
  float: left;
  width: 100%;
  display: inline-flex;
  margin-top: 16px;
}

.linkedAccountImageBlock .singleLinkedAccountImageBlock
{
  margin-right: 16px;
}

.singleLinkedAccountImageBlock,
.singleReviewImageBlock
{
  position: relative;
}

.singleReviewImageBlock
{
  float: left;
}

.userBlockReview
{
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 16px;

  background: rgba(241, 241, 241, 1);
  border-radius: 20px;

  padding: 16px;
  box-sizing: border-box;
}

.singleLinkedAccountImageBlock .sungleImageActiveUsers,
.singleReviewImageBlock .singleImageActiveUsersReview
{
  position: absolute;
  bottom: 7px;
  right: 0px;
  width: 12px;
}

.singleReviewDescriptionBlock
{
  float: left;
  width: 88%;

  color: #747474;
  font-style: italic;
  font-weight: 500;
}

.singleReviewImageBlockParent
{
  float: left;
  width: 12%;
}

.singleReviewDescriptionLinkBlock
{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 15px;
/* identical to box height */

letter-spacing: -0.165px;
text-decoration-line: underline;

/* blue */

color: #7495C2;

margin-top: 8px;
}

.singleReviewDescriptionLinkBlock a
{
  color: #7495C2;
}

.singleReviewDescriptionLinkBlock a.active 
{
  color: #7495C2; 
}



.singleLinkedAccountImageBlock .singleImage
{
  width: 72px;
  height: 72px;
  object-fit: cover;
  object-position: 40% 10%;
  border-radius: 30px;
}


.profileReviewAccountSection 
{
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.profileReviewAccountSection label
{
  color: #747474;
  font-size: 16px;
  font-weight: bold;
}

.reviewAccountBlock
{
  float: left;
  width: 100%;
  /* display: inline-flex; */
  margin-top: 16px;
}

.singleReviewImageBlock .singleImage
{
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: 40% 10%;
  border-radius: 30px;
}


.bannerContentSection
{
  float: left;
  width: 100%;
  margin-top: 370px;
}

.bannerContentSection p 
{
  color: #505050;
  font-weight: 100;
  font-size: 18px;
  text-align: center;
  padding: 0px 30px;
}

.bannerTitleSection
{
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 0px 25px;
  box-sizing: border-box;
  text-align: center;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.165px;

  /* grey dark */

  color: #505050;
}

.bannerImagesSection
{
  margin-top: 36px;
  float: left;
  width: 100%;

  margin-bottom: 10px;
}

.bannerImagesSection input
{
  display: block;
  margin: 0 auto;

  width: 100%;
  padding: 6px 10px;
  box-sizing: border-box;
}

.rightFilterBox
{
  width: 100%;
  background: #F1F1F1;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 26px;
  float: left;
}

.filterMainBox
{
  float: left;
  width: 100%;
}

.filterMainBox h4
{
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 30px;
  margin-left: 25px;
  margin-top: 24px;
  float: left;
}

.filterMainBodySection
{
  float: left;
  width: 100%;
  margin: 10px 20px;
}

.socializeField
{
  float: left;
  width: 80%;
}

.fieldsSection
{
  display: inline-grid;
}
.filterCheckBox
{
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 8px;
}

/* .fieldsSection label span
{
  margin-left: 10px;
} */

.filterCheckBoxLabel
{
  margin-left: 14px !important;
}
